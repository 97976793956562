var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',[_c('b-card-header',{attrs:{"header-class":"pt-0"}},[_c('h4',[_vm._v("تفاصيل الوحدة "+_vm._s(_vm.DetailsUnit.name))])]),_c('b-card-body',[_c('ValidationObserver',{ref:"form"},[_c('b-row',[_c('b-col',{attrs:{"lg":"4"}},[_c('ek-input-text',{attrs:{"name":"name","label":" اسم الجامعة ","placeholder":"ادخل اسم الجامعة"},model:{value:(_vm.DetailsUnit.universityName),callback:function ($$v) {_vm.$set(_vm.DetailsUnit, "universityName", $$v)},expression:"DetailsUnit.universityName"}}),_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{attrs:{"lg":"6","md":"12"}},[_c('ek-input-select',{attrs:{"options":_vm.years,"placeholder":"السنة","name":" اختر السنة","label":"السنة","rules":[
                                        {
                                            type: 'required',
                                            message: 'السنة مطلوبة',
                                        } ]},model:{value:(_vm.DetailsUnit.yearName),callback:function ($$v) {_vm.$set(_vm.DetailsUnit, "yearName", $$v)},expression:"DetailsUnit.yearName"}})],1),_c('b-col',{staticClass:"pl-2",attrs:{"lg":"6","md":"12"}},[_c('ek-input-select',{attrs:{"options":_vm.semesters,"placeholder":"اختر الفصل","name":" اختر الفصل","label":"الفصل","rules":[
                                        {
                                            type: 'required',
                                            message: 'الفصل مطلوب',
                                        } ]},model:{value:(_vm.DetailsUnit.semesterName),callback:function ($$v) {_vm.$set(_vm.DetailsUnit, "semesterName", $$v)},expression:"DetailsUnit.semesterName"}})],1)],1),_c('ek-input-text',{attrs:{"readonly":"","type":"number","name":"name","label":" عدد الاقسام ","placeholder":"عدد الاقسام"},model:{value:(_vm.DetailsUnit.chaptersCount),callback:function ($$v) {_vm.$set(_vm.DetailsUnit, "chaptersCount", $$v)},expression:"DetailsUnit.chaptersCount"}})],1),_c('b-col',{attrs:{"lg":"4"}},[_c('ek-input-text',{attrs:{"rules":[
                                {
                                    type: 'required',
                                    message: '  يرجى اختيار الكلية',
                                } ],"name":"classes","placeholder":"تابع الكلية","label":"الكلية","clearable":""},model:{value:(_vm.DetailsUnit.facultyName),callback:function ($$v) {_vm.$set(_vm.DetailsUnit, "facultyName", $$v)},expression:"DetailsUnit.facultyName"}}),_c('b-row',{attrs:{"no-gutters":""}},[_c('b-col',{staticClass:"pl-2",attrs:{"lg":"6","md":"12"}},[_c('ek-input-select',{attrs:{"options":_vm.semesters,"placeholder":"اسم المادة","name":" اسم المادة","label":"اسم المادة","rules":[
                                        {
                                            type: 'required',
                                            message: 'اسم المادة مطلوب',
                                        } ]},model:{value:(_vm.DetailsUnit.subjectName),callback:function ($$v) {_vm.$set(_vm.DetailsUnit, "subjectName", $$v)},expression:"DetailsUnit.subjectName"}})],1),_c('b-col',{staticClass:"pl-2",attrs:{"lg":"6","md":"12"}},[_c('ek-input-text',{attrs:{"placeholder":"اسم الاستاذ","name":" اسم الاستاذ","label":" اسم الاستاذ","rules":[
                                        {
                                            type: 'required',
                                            message: 'اسم الاستاذ مطلوب',
                                        } ]},model:{value:(_vm.DetailsUnit.tetcherName),callback:function ($$v) {_vm.$set(_vm.DetailsUnit, "tetcherName", $$v)},expression:"DetailsUnit.tetcherName"}})],1)],1)],1),_c('b-col',{staticClass:"position-relative",attrs:{"lg":"4"}},[_c('b-dropdown',{staticClass:"position-absolute",attrs:{"size":"lg","variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('unicon',{attrs:{"name":"ellipsis-v","fill":"#2EC4B6"}})]},proxy:true}])},[_c('b-dropdown-item',{ref:"myUploader",attrs:{"href":"#"},on:{"click":function($event){return _vm.fileWindowApi(_vm.onUpload)}}},[_c('unicon',{staticStyle:{"width":"25px"},attrs:{"name":"edit-alt","fill":"#2EC4B6"}}),_c('span',[_vm._v("تعديل الصورة")])],1)],1),_c('img',{staticClass:"h-100 w-100 rounded",staticStyle:{"object-fit":"cover"},attrs:{"src":_vm.$store.getters['app/domainHost'] +
                                '/' +
                                _vm.DetailsUnit.imageUrl,"alt":""}})],1)],1)],1)],1)],1),_c('ek-table',{attrs:{"columns":_vm.columns,"items":_vm.DetailsUnit.chapters,"no_select":true,"no_delete":true},on:{"details":_vm.detailsCourse},scopedSlots:_vm._u([{key:"table-header",fn:function(){return [_c('div',{staticClass:"d-flex p-1"},[_c('unicon',{staticClass:"pr-1",attrs:{"fill":"gray","name":"bookmark"}}),_c('h4',[_vm._v("أقسام الوحدة")])],1)]},proxy:true},{key:"items.dateCreated",fn:function(ref){
                                var value = ref.value;
return [_vm._v(" "+_vm._s(value ? new Date(value).toLocaleDateString() : "_")+" ")]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }